import "./header.js";
import scrollTop from "./utilites/scrollTop";

try {
  const ar1 = [0, 1, 2, 3, 4, 5, 6];
  const ar2 = [5, 22, 15, 38, 30, 29, 37];
  const ar3 = [10, 19, 18, 40, 52, 63, 72];

  let p1, p2, p3, d1, d2;
  let ind = 0;
  let length = ar1.length;

  const emp = document.querySelector("span.about-chart__mob-emp");
  const proj = document.querySelector("span.about-chart__mob-proj");
  const years = document.querySelector("span.about-chart__mob-years");
  const year = document.querySelector(".about-chart__mob-year");

  const chart_mob = document.querySelector(".about-chart__mob");
  const chart_nav = document.querySelector(".about-chart__mob-nav");

  const getW = (i) => {
    let w = ar1[i] + ar2[i] + ar3[i];

    p1 = (ar1[i] / w) * 100;
    p2 = (ar2[i] / w) * 100;
    p3 = (ar3[i] / w) * 100;

    d1 = p1 * -1;
    d2 = (p1 + p2) * -1;

    chart_mob.style.setProperty("--w1", `${p1} 100`);
    chart_mob.style.setProperty("--w2", `${p2} 100`);
    chart_mob.style.setProperty("--w3", `${p3} 100`);
    chart_mob.style.setProperty("--d1", d1);
    chart_mob.style.setProperty("--d2", d2);

    year.innerText = 2015 + i;
    years.innerText = ar1[i];
    emp.innerText = ar2[i];
    proj.innerText = ar3[i];

    chart_nav.querySelector("span.active").classList.remove("active");
    chart_nav.querySelectorAll("span.dot").forEach((b, index) => {
      if (index == i) {
        b.classList.add("active");
      }
    });
  };

  //buttons

  function paginationButton(pageCount) {
    for (let i = 0; i < pageCount; i++) {
      let btn = document.createElement("span");
      btn.classList.add("dot");
      if (i == 1) {
        btn.classList.add("active");
      }
      btn.addEventListener("click", function () {
        ind = i;
        getW(ind);
      });
      chart_nav.appendChild(btn);
    }
  }
  paginationButton(length);
  getW(ind);

  // swipe
  const offset = 60;
  let xDown, yDown;

  document
    .querySelector(".about-chart__mob-wrap")
    .addEventListener("touchstart", (e) => {
      const firstTouch = getTouch(e);
      xDown = firstTouch.clientX;
      yDown = firstTouch.clientY;
    });

  document
    .querySelector(".about-chart__mob-wrap")
    .addEventListener("touchend", (e) => {
      if (!xDown || !yDown) {
        return;
      }

      const { clientX: xUp, clientY: yUp } = getTouch(e);
      const xDiff = xDown - xUp;
      const yDiff = yDown - yUp;
      const xDiffAbs = Math.abs(xDown - xUp);
      const yDiffAbs = Math.abs(yDown - yUp);

      // at least <offset> are a swipe
      if (Math.max(xDiffAbs, yDiffAbs) < offset) {
        return;
      }

      if (xDiffAbs > yDiffAbs) {
        if (xDiff > 0) {
          ind++;
          if (ind > length - 1) {
            ind = 0;
          }
          getW(ind);
        } else {
          ind--;
          if (ind < 0) {
            ind = length - 1;
          }
          getW(ind);
        }
      } else {
        return;
      }
    });

  function getTouch(e) {
    return e.changedTouches[0];
  }
} catch (e) {
  console.log("CHART!!!");
}

try {
  const btn_to_top = document.querySelector(".btn-to-top ");

  btn_to_top.addEventListener("click", function (e) {
    e.preventDefault();
    scrollTop();
  });

  window.addEventListener("scroll", () => {
    if (window.pageYOffset >= 500) {
      btn_to_top.classList.remove("hide");
    } else {
      btn_to_top.classList.add("hide");
    }
  });
} catch (e) {
  console.error(e);
}

try {
  const arr_projects = [
    0, 5, 10, 22, 10, 15, 30, 38, 27, 30, 36, 29, 25, 37, 30,
  ];
  const arr_employees = [
    0, 10, 8, 19, 15, 18, 25, 40, 40, 52, 55, 63, 60, 72, 70,
  ];

  const svg = document.querySelector("svg");
  const path_projects = document.querySelector(".line_projects");
  const path_employees = document.querySelector(".line_employees");
  const hoverElements = document.querySelector(".rect-box");
  const out = document.querySelector(".about-chart__out");
  const angle = 18;
  const w = 500 / (arr_projects.length - 2);
  const h = 350;
  let line;
  let chart_active = 6;

  const drawLine = (arr, path) => {
    arr.forEach((el, i) => {
      i == 0
        ? (line = `M${i * w} ${h - (el * h) / 100}, C`)
        : (line += ` ${(i - 1) * w + angle} ${h - (arr[i - 1] * h) / 100}, 
                  ${w * i - angle} ${h - (el * h) / 100}, 
                  ${w * i} ${h - (el * h) / 100}`);
    });
    path.setAttribute("d", line);
  };
  const drawHoverItem = (arr) => {
    arr.forEach((el, i) => {
      if (i == 0) return;
      if (i % 2 !== 0) {
        hoverElements.innerHTML += `<g class='rect-item ${
          chart_active == i / 2 + 0.5 ? "active" : ""
        } '> 
          <path  
            data-emp='${arr_projects[i]}' 
            data-proj='${el}' 
            data-yaer='${i / 2 - 0.5}' 
            fill='url(#Gradient4)' 
            class='rect' 
            d='M${i * w - w} ${h} V15 Q${i * w - w} 1 ${i * w - w + 15} 1 H${
          i * w + w - 15
        } Q${i * w + w} 1 ${i * w + w} 15 L${i * w + w} ${h}'/>
  
          <rect fill='url(#Gradient1)' class='line' x="${i * w}" y="${
          h - (el * h) / 100
        }" width="1" height="${h - el}"/>
          <rect fill='url(#Gradient3)' class='line-decor d1' x="${
            i * w - w / 4
          }" y="${h - (el * h) / 100 + 30}" width="1" height="50"/>
          <rect fill='url(#Gradient2)' class='line-decor d2' x="${
            i * w + w / 4
          }" y="${h - (el * h) / 100 + 30}" width="1" height="50"/>
  
          <circle class='c1' fill='#fff2fe' cx="${w * i}" cy="${
          h - (el * h) / 100
        }" r="6"/>
          <circle class='c2' fill='#fff' cx="${w * i}" cy="${
          h - (el * h) / 100
        }" r="17"/>
          <circle class='c3' fill='#fff' cx="${w * i}" cy="${
          h - (el * h) / 100
        }" r="30"/>
        </g>`;
      }
    });
  };

  drawLine(arr_projects, path_projects);
  drawLine(arr_employees, path_employees);
  drawHoverItem(arr_employees);

  const addActive = (arr, ind) => {
    document.querySelectorAll(arr).forEach((elem, i) => {
      elem.classList.remove("active");
      if (i == ind) {
        elem.classList.add("active");
      }
    });
  };

  document.querySelectorAll(".rect-item").forEach((el, i) => {
    el.addEventListener("mouseenter", () => {
      addActive(".rect-item", i);
    });
  });

  document.querySelectorAll("path.rect").forEach((el) => {
    el.addEventListener("mouseenter", (e) => {
      out.querySelector(".about-chart__out-employees").innerHTML =
        e.target.dataset.emp;
      out.querySelector(".about-chart__out-projects").innerHTML =
        e.target.dataset.proj;
      out.querySelector(".about-chart__out-years").innerHTML =
        e.target.dataset.yaer;
    });
  });
} catch (e) {
  // console.error(e);
  console.log("chart!!!");
}
