const nav = document.querySelector(".nav");
const nav_mob = document.querySelector(".nav-mobile");

let indentTop = window.innerWidth > 991 ? 100 : 50;
window.addEventListener("resize", () => {
  indentTop = window.innerWidth > 991 ? 100 : 50;
});

function visibleNav() {
  if (window.pageYOffset > indentTop) {
    nav.classList.add("floatingNav");
  } else {
    nav.classList.remove("floatingNav");
  }
}
visibleNav();

let floatingNav_active = true;
window.addEventListener("scroll", function () {
  if (floatingNav_active) visibleNav();
});

document.querySelector(".ham1").addEventListener("click", function () {
  if (floatingNav_active) {
    nav.classList.add("floatingNav");
  } else {
    if (window.pageYOffset < indentTop) {
      nav.classList.remove("floatingNav");
    }
  }

  this.classList.toggle("active");
  nav_mob.classList.toggle("active");
  if (nav_mob.classList.contains("active")) {
    document.body.classList.add("overflow-hidden");
    document.querySelector(".btn-to-top").style.zIndex = "0";
  } else {
    document.body.classList.remove("overflow-hidden");
    document.querySelector(".btn-to-top").style.zIndex = "100";
  }
  floatingNav_active = !floatingNav_active;
});
